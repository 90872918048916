.speech-stalk-left{
    height:15px;
    width:15px;
    transform: rotate(45deg);
    top: 15px;
    left: -3px;
    background: white;
    position: absolute;
    box-shadow: 2px 3px 4px -2px #888;
    z-index: 4;

  }
  
  .speech-stalk-right{
    height:15px;
    width:15px;
    transform: rotate(45deg);
    top: 15px;
    right: -3px;
    background: white;
    position: absolute;

    box-shadow: 3px 2px 4px -2px #888;
    z-index: 4;

  }
  
  .speech-box-container{
    /* border: 2px solid yellow; */
    display: inline-block;
    position: relative;
    margin: 6px;
    width: 250px;
    cursor: pointer;
  }
  
  .speech-box-right{
    background: white;
    padding: 15px 18px;
    border-radius: 20px;
    display: inline-block;
    /* max-width: 200px; */
    text-align: left;
    float: right;
    position: relative;
    box-shadow: -2px 3px 4px -2px #888;
    z-index: 5;

  }
  
  .speech-box-left{
    background: white;
    padding: 15px 18px;
    border-radius: 20px;
    display: inline-block;
    /* max-width: 200px; */
    text-align: left;
    float: left;
    position: relative;
    box-shadow: 2px 3px 4px -2px #888;
    z-index: 5;
  }
  
  .dialogue-box-appear{
    animation-name: dialogue-box-appear-animation;
    animation-duration: 1s;
  }

 

  #next-icon{
    font-size: 0.5em;
    color: orange;
    animation: next-icon-flash-animation; 
    animation-duration: 1s;
    animation-iteration-count: infinite;
  }

  @keyframes next-icon-flash-animation {
    0%{
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100%{
      opacity: 0;
    }
  }

  #invisible-next-icon{
    font-size: 0.5em;
    opacity: 0;
  }